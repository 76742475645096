import APIService from './APIService';

export default {
  getCargas() {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get('/geo-perdas/cargas-bdgds/get-all')
        .then((response) => resolve(response.data.data))
        .catch((error) => reject(error));
    });
  },
  getResumoCargasBdgdsServidor(servidorId) {
    return APIService.apiCall().get(
      `/geo-perdas/cargas-bdgds/get-resumo-cargas-bdgds-servidor/${servidorId}`
    );
  },
  save(postData) {
    return APIService.apiCall().post(
      '/geo-perdas/cargas-bdgds/save',
      JSON.stringify(postData)
    );
  },
  deletarPorStatus(empresaId, status) {
    return APIService.apiCall().delete(
      `/geo-perdas/cargas-bdgds/deletar-por-status/${empresaId}/${status}`
    );
  },
  deletar(id) {
    return APIService.apiCall().delete(
      `/geo-perdas/cargas-bdgds/${id}`
    );
  },
  baixarLogsTecnicos(id) {
    return APIService.apiCall().get(`/geo-perdas/cargas-bdgds/logs-tecnicos/${id}`, {
      responseType: 'blob',
      ...{
        timeout: 60 * 60 * 1000
      }
    });
  }
};
